<template>
  <div>
    <p>点击了{{count}}次</p>
    <button @click="Add">加</button>
    <button @click="Reduce">减</button>
    <div v-for="todo in todos" v-bind:key="todo.id">
      {{todo.name}}
    </div>
    <img :src="url"/>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from "vuex";
  export default {
    name: "Home",
    created: function () {
      if(this.$route.query.auth_code && this.$route.query.expires_in){
        this.axiosCode ({
          auth_code:this.$route.query.auth_code,
          expires_in:this.$route.query.expires_in
        });
      }else{
        this.axiosData ({
          callback:'http://tc.quanmai.cloud/home'
        });
      }
    },
    computed: {
      ...mapState ("home", ["count", "todos" , "url"]),
    },
    methods: {
      ...mapMutations ("home", ["Add", "Reduce"]),
      ...mapActions ("home", ["axiosData","axiosCode"])
    }
  };
</script>
